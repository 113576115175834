import { library } from '@fortawesome/fontawesome-svg-core'

import {
  faCircle,
  faCheck,
  faTimes as faTimesFAS,
  faQuestionCircle as faQuestionCircleFAS,
  faUser as faUserFAS,
  faBriefcase as faBriefcaseFAS,
  faLanguage as faLanguageFAS,
  faGlobeEurope as faGlobeEuropeFAS,
  faShieldAlt,
  faHeartBroken,
  faSignOutAlt,
  faArrowLeft,
  faCamera as faCameraFAS,
} from '@fortawesome/free-solid-svg-icons'

import {
  faPennant,
  faRepeat,
  faLockAlt,
  faHome as fasHome,
  faBell as fasBell,
  faBooks as fasBooks,
  faUsersClass as fasUsersClass,
  faChartNetwork as fasChartNetwork,
  faFunction as fasFunction,
} from '@fortawesome/pro-solid-svg-icons'

import {
  faChevronLeft,
  faCog,
  faTimes,
  faSun,
  faMoon,
  faWater,
  faBrain,
  faChartLine,
  faStopwatch,
  faListOl,
  faUserHeadset,
  faVolumeOff,
  faVolumeUp,
  faCircle as falCircle,
  faEye,
  faEyeSlash,
  faHome,
  faBell,
  faUser,
  faChartNetwork,
  faCamera,
  faBooks,
  faUsersClass,
} from '@fortawesome/pro-light-svg-icons'

import { faSpinnerThird } from '@fortawesome/pro-duotone-svg-icons'

// General
library.add(
  faChevronLeft,
  faCog,
  faTimes,
  faTimesFAS,
  faCircle,
  falCircle,
  faCheck,
  faPennant,
  faRepeat,
  faSun,
  faMoon,
  faSpinnerThird,
  faVolumeOff,
  faVolumeUp,
  faEye,
  faEyeSlash,
  faHome,
  fasHome,
  faBell,
  fasBell,
  faUser,
  faChartNetwork,
  faQuestionCircleFAS,
  faUserFAS,
  faBriefcaseFAS,
  faLanguageFAS,
  faGlobeEuropeFAS,
  faShieldAlt,
  faLockAlt,
  faHeartBroken,
  faSignOutAlt,
  faArrowLeft,
  faCamera,
  faCameraFAS,
  faBooks,
  fasBooks,
  faUsersClass,
  fasUsersClass,
  fasChartNetwork,
  fasFunction,
)

// Index Icons
library.add(faWater, faBrain, faChartLine, faStopwatch, faListOl, faUserHeadset)
