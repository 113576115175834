// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-app-js": () => import("./../../../src/pages/app.js" /* webpackChunkName: "component---src-pages-app-js" */),
  "component---src-pages-confirm-js": () => import("./../../../src/pages/confirm.js" /* webpackChunkName: "component---src-pages-confirm-js" */),
  "component---src-pages-dashboard-js": () => import("./../../../src/pages/dashboard.js" /* webpackChunkName: "component---src-pages-dashboard-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-page-cookies-js": () => import("./../../../src/pages/page/cookies.js" /* webpackChunkName: "component---src-pages-page-cookies-js" */),
  "component---src-pages-page-help-js": () => import("./../../../src/pages/page/help.js" /* webpackChunkName: "component---src-pages-page-help-js" */),
  "component---src-pages-page-our-story-js": () => import("./../../../src/pages/page/our-story.js" /* webpackChunkName: "component---src-pages-page-our-story-js" */),
  "component---src-pages-page-privacy-js": () => import("./../../../src/pages/page/privacy.js" /* webpackChunkName: "component---src-pages-page-privacy-js" */),
  "component---src-pages-page-terms-js": () => import("./../../../src/pages/page/terms.js" /* webpackChunkName: "component---src-pages-page-terms-js" */),
  "component---src-pages-profile-js": () => import("./../../../src/pages/profile.js" /* webpackChunkName: "component---src-pages-profile-js" */),
  "component---src-pages-reset-js": () => import("./../../../src/pages/reset.js" /* webpackChunkName: "component---src-pages-reset-js" */),
  "component---src-pages-settings-js": () => import("./../../../src/pages/settings.js" /* webpackChunkName: "component---src-pages-settings-js" */),
  "component---src-pages-signin-js": () => import("./../../../src/pages/signin.js" /* webpackChunkName: "component---src-pages-signin-js" */),
  "component---src-pages-signup-js": () => import("./../../../src/pages/signup.js" /* webpackChunkName: "component---src-pages-signup-js" */)
}

